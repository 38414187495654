import EndpointController from "controllers/endpoint_controller";
import { replaceParams } from "utils/ajax";
import { fire } from "utils/events";

export default class extends EndpointController {
  static targets = ["link", "loading", "output"];
  static values = {
    autoload: Boolean,
    endpoint: String,
    isLoading: Boolean,
    key: String,
    params: Object
  };

  connect() {
    if (this.autoloadValue) {
      this.load(this.endpoint());
    }
  }

  load(url, data) {
    this.isLoadingValue = true;
    super.load(url, data);
  }

  refresh() {
    this.load(this.endpoint());
  }

  refreshAndPreventDefault(event) {
    event.preventDefault();
    this.refresh();
  }

  handleLoadFromElement(event) {
    event.preventDefault();
    this.load(event.currentTarget.href);
  }

  onSuccess(node, status, xhr) {
    const { response } = xhr;
    super.onSuccess(node, status, xhr);
    this.isLoadingValue = false;
    this.output.innerHTML = response;
    fire(this.element, "partial:success", { response });
  }

  onError(node, statusMsg, xhr) {
    super.onError(node, statusMsg, xhr);

    this.isLoadingValue = false;

    switch (xhr.status) {
      case 404:
        this.displayError(`Could not find partial for: ${this.endpoint()}`);
        break;
      case 500:
        this.displayError(
          "There was an error loading this partial.",
          xhr.response
        );
        break;
    }

    fire(this.element, "partial:error", xhr);
  }

  displayError(msg, detail) {
    let errorMsg = `<p class="text-danger">${msg}</p>`;

    if (detail) {
      errorMsg += `<div class="d-none"><pre>${detail}</pre></div>`;
    }

    this.output.innerHTML = errorMsg;
  }

  isLoadingValueChanged() {
    this.element.toggleAttribute("data-loading", this.isLoadingValue);

    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.toggle("d-none", !this.isLoadingValue);
    }
  }

  endpoint(detail) {
    if (detail) {
      return replaceParams(this.url, detail);
    } else {
      return this.url;
    }
  }

  get url() {
    if (this.hasLinkTarget) {
      return this.linkTarget.href;
    } else {
      return this.endpointValue;
    }
  }

  get output() {
    return this.hasOutputTarget ? this.outputTarget : this.element;
  }
}
