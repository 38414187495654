import Rails from "@rails/ujs";

const ENTER = 13;
const ESCAPE = 27;
const ZERO = 48;
const ONE = 49;
const TWO = 50;
const THREE = 51;
const FOUR = 52;
const FIVE = 53;
const SIX = 54;
const SEVEN = 55;
const EIGHT = 56;
const NINE = 57;
const Z = 90;

function fire(obj, name, data) {
  return Rails.fire(obj, name, data);
}

function toast({ key, ...details }) {
  key = key || "regular";
  return fire(document, `toaster:toast:${key}`, details);
}

["success", "error"].forEach(type => {
  toast[type] = options => toast({ type, ...options });
});

export {
  fire,
  toast,
  ENTER,
  ESCAPE,
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  Z
};
