import ApplicationController from "controllers/application_controller";
import Modal from "bootstrap.native/dist/components/modal-native.esm.js";

export default class extends ApplicationController {
  connect() {
    this.modal = new Modal(this.element);
  }

  disconnect() {
    this.modal.dispose();
    this.modal = null;
  }

  show() {
    this.modal.show();
  }

  shown() {
    this.classList.add("modal-shown");
  }

  hide() {
    this.modal.hide();
  }

  hidden() {
    this.classList.remove("modal-shown");
  }

  remote(event) {
    event.preventDefault();

    this.dispatch("load", {
      namespace: "modal-operator",
      detail: { url: event.currentTarget.href }
    });
  }
}
