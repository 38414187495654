import { Response } from "./response";

export class Request {
  constructor(method, url, options = {}) {
    this.method = method;
    this.url = url;
    this.options = options;
  }

  async perform() {
    const response = new Response(
      await fetch(this.url, this.fetchOptions),
      this
    );

    if (response.unauthenticated && response.authenticationURL) {
      return Promise.reject(
        (window.location.href = response.authenticationURL)
      );
    } else if (response.ok && response.isJs) {
      response.processJs();
    }

    return response;
  }

  get fetchOptions() {
    return {
      method: this.method,
      headers: this.headers,
      body: this.body,
      signal: this.signal,
      credentials: "same-origin",
      redirect: "follow",
    };
  }

  get headers() {
    return compact({
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": this.csrfToken,
      "Content-Type": this.contentType,
      Accept: this.accept,
    });
  }

  get csrfToken() {
    return document.head.querySelector("meta[name=csrf-token]")?.content;
  }

  get contentType() {
    if (this.options.contentType) {
      return this.options.contentType;
    } else if (this.body == null || this.body instanceof FormData) {
      return undefined;
    } else if (this.body instanceof File) {
      return this.body.type;
    } else if (this.options.jsonBody) {
      return "application/json";
    } else {
      return "application/octet-stream";
    }
  }

  get accept() {
    switch (this.responseKind) {
      case "html":
        return "text/html, application/xhtml+xml";
      case "json":
        return "application/json";
      default:
        return "*/*";
    }
  }

  get body() {
    if (this.options.jsonBody) {
      return JSON.stringify(this.options.jsonBody);
    } else {
      return this.options.body;
    }
  }

  get responseKind() {
    return this.options.responseKind || "html";
  }

  get signal() {
    return this.options.signal;
  }
}

function compact(object) {
  const result = {};
  for (const key in object) {
    const value = object[key];
    if (value !== undefined) {
      result[key] = value;
    }
  }
  return result;
}
