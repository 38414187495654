import { Controller } from "@hotwired/stimulus";
import { ajax, replaceParams } from "utils/ajax";
import { fire } from "utils/events";

const slice = Array.prototype.slice;

export default class extends Controller {
  static values = {
    endpoint: String,
    method: String,
    autoload: Boolean
  };

  load(url, data) {
    ajax({
      type: this.methodValue || "GET",
      url,
      data,
      beforeSend: this.onBeforeSend.bind(this),
      success: this.onSuccess.bind(this),
      error: this.onError.bind(this),
      complete: this.onComplete.bind(this)
    });
  }

  handleLoad({ detail }) {
    this.load(this.endpoint(detail), detail);
  }

  onBeforeSend(xhr, options) {
    fire(this.element, "ajax:beforeSend", [xhr, options]);
    return true;
  }

  onSuccess() {
    const args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    return fire(this.element, "ajax:success", args);
  }

  onError() {
    const args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    return fire(this.element, "ajax:error", args);
  }

  onComplete() {
    const args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    return fire(this.element, "ajax:complete", args);
  }

  endpoint(detail) {
    if (detail) {
      return replaceParams(this.endpointValue, detail);
    } else {
      return this.endpointValue;
    }
  }
}
