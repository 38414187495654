import ApplicationController from "controllers/application_controller";
import { request, appendQuery } from "utils/ajax";
import { toast } from "utils/events";
import { removeNode, strToEl } from "utils/manipulation";
import Modal from "bootstrap.native/dist/components/modal-native.esm.js";

export default class extends ApplicationController {
  static values = {
    href: String,
  };

  disconnect() {
    // Clean up before snapshotting

    // Remove modal backdrop
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) removeNode(backdrop);

    // Remove .modal-open class from body
    if (this.classList.contains("modal-open")) {
      this.classList.remove("modal-open");
    }
  }

  async load({ url, type, data, local = false }) {
    if (data) {
      url = appendQuery(url, data);
    }

    const response = await request.perform(type || "GET", url);

    if (response.ok) {
      const html = await response.html;
      this.show(html, local);
    } else {
      this.error(response);
    }
  }

  loadFromEvent({ detail }) {
    this.load(detail);
  }

  // Launch from a click event
  launch(event) {
    event.preventDefault();
    this.load({ url: this.hrefValue || event.currentTarget.href });
  }

  localLink(event) {
    event.preventDefault();
    this.load({ url: this.hrefValue || event.currentTarget.href, local: true });
  }

  show(response, local) {
    if (local) {
      const element = strToEl(response);
    } else {
      const element = strToEl(response);
      document.body.appendChild(element);

      this.modal = new Modal(element);
      this.modal.show();

      element.addEventListener("hidden.bs.modal", () => removeNode(element));
    }
  }

  error(response) {
    let content = "An error occurred trying to load the popup";

    if (response?.status === 404) {
      content = "The resource could not be found when trying to load the popup";
    }

    toast.error({ content });
  }
}
