import { Controller } from "@hotwired/stimulus";
import { fire } from "utils/events";
import { strToEl } from "utils/manipulation";
import { closest, getFieldName } from "utils/selectors";
import { scrollTo } from "utils/scroll";
import Rails from "@rails/ujs";

const DISABLED_ATTR = "data-form-disabled";
const DISABLE_IGNORE_ATTR = "data-form-disable-ignore";

export default class extends Controller {
  static targets = ["error", "errorContainer", "errorHeading", "loading"];
  static events = ["beforeSend", "success", "error", "complete"];

  static values = {
    isLoading: Boolean
  };

  connect() {
    this.watchRemote();
    // this.initLoader();

    this.element.addEventListener("form:loading", () => {
      this.isLoadingValue = true;
    });

    this.element.addEventListener("form:finished", () => {
      this.isLoadingValue = false;
    });

    this.element.addEventListener(
      "form:invalid",
      this.handleInvalid.bind(this)
    );

    this.element.addEventListener(
      "form:clearErrors",
      this.handleClearErrors.bind(this)
    );

    this.element.addEventListener("form:disable", this.disable.bind(this));
    this.element.addEventListener("form:enable", this.enable.bind(this));
  }

  watchRemote() {
    this.events.forEach(event => {
      this.element.addEventListener(
        `ajax:${event}`,
        this.handleEvent.bind(this, event)
      );
    });
  }

  isLoadingValueChanged() {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.toggle("loading", this.isLoadingValue);

      if (this.isLoadingValue) {
        this.loadingTarget.setAttribute("disabled", "disabled");
      } else {
        this.loadingTarget.removeAttribute("disabled");
      }
    }
  }

  handleEvent(name, event) {
    if (event.target !== this.element) {
      return;
    }

    if (typeof this[name] === "function") {
      this[name](event);
    }
  }

  handleInvalid({ detail }) {
    this.processErrors(detail);
  }

  handleClearErrors() {
    this.clearErrors();
  }

  beforeSend() {
    this.isLoadingValue = true;
    this.clearErrors();
  }

  success({ detail }) {
    const [response] = detail;
    this.isLoadingValue = false;
    this.fireEvents("success", response);
  }

  error(event) {
    this.isLoadingValue = false;

    const { detail } = event;
    const [response, statusText, xhr] = detail;

    this.fireEvents("error", detail);

    switch (xhr.status) {
      case 403:
        this.displayError(response.error);
        break;
      case 404:
        this.displayError("There was an issue finding the resource");
        break;
      case 422:
        this.processErrors(response);
        break;
    }

    this.focusFirstError();
  }

  displayError(msg) {
    if (this.hasErrorHeadingTarget) {
      this.errorHeadingTarget.innerText = msg;
      this.errorHeadingTarget.classList.remove("d-none");
    }
  }

  processErrors(response) {
    const { errors } = response;
    const modelName = response.model_name;
    const keys = Object.keys(errors || {});

    keys.forEach(key => {
      const msgs = errors[key];

      if (msgs.length) {
        this.addContainerError(key, modelName, msgs);
        this.findInputAndAddError(key, modelName, msgs);
      }
    });
  }

  addContainerError(key, modelName, msgs) {
    this.errorContainerTargets
      .filter(target => {
        return target.getAttribute("data-key") === key;
      })
      .forEach(container => {
        container.innerHTML = this.buildError(msgs[0]);
        container.classList.remove("d-none");
      });
  }

  findInput(key, modelName) {
    let field;

    field = this.element.querySelector(`[data-form-error="${key}"]`);
    if (field) return field;

    field = this.element.querySelector(`[data-field="${key}"]`);
    if (field) return field;

    field = this.element.querySelector(
      `[name="${getFieldName(key, modelName)}"]`
    );
    if (field) return field;

    return this.element.querySelector(`[name="${key}"]`);
  }

  findInputAndAddError(key, modelName, msgs) {
    const input = this.findInput(key, modelName);
    input && this.addInputError(input, msgs);
  }

  addInputError(input, msgs) {
    let container = input;
    if (container.parentNode.classList.contains("input-group")) {
      container = container.parentNode;
    }

    if (typeof msgs === "string") {
      msgs = [msgs];
    }

    if (container.hasAttribute("data-form-error")) {
      container.appendChild(strToEl(this.buildError(msgs[0])));
    } else {
      input.classList.add("is-invalid");
      container.insertAdjacentHTML("afterend", this.buildError(msgs[0]));
    }
  }

  buildError(msg) {
    return `<div
              class="form-text text-danger"
              data-${this.identifier}-target="error">
              ${msg}
            </div>`;
  }

  focusFirstError() {
    if (this.hasErrorTarget) {
      const container =
        this.errorTarget.closest(".form-group, .form-label-group") ||
        this.errorTarget.closest(".form-container") ||
        this.errorTarget;
      const input = container.querySelector(".form-control");

      scrollTo(container, { tolerance: 20 });

      setTimeout(() => {
        input && input.focus();
      }, 500);
    }
  }

  clearErrors() {
    this.errorTargets.forEach(error => error.remove());
    this.errorContainerTargets.forEach(error => error.classList.add("d-none"));

    this.element.querySelectorAll(".is-invalid").forEach(input => {
      input.classList.remove("is-invalid");
    });
  }

  handleClear(event) {
    event.preventDefault();
    this.reset();
    this.submit();
  }

  fireEvents(type, detail) {
    fire(this.element, `${this.identifier}:${type}`, detail);

    this.eventsFor(type).forEach(event => {
      fire(this.element, event, detail);
    });
  }

  eventsFor(type) {
    let events = [];

    if (this.data.get(`${type}Event`)) {
      events = this.data.get(`${type}Event`).split(" ");
    }

    return events;
  }

  disable() {
    const formElements = this.element.querySelectorAll(
      "input:enabled, select:enabled, textarea:enabled, button:enabled"
    );

    formElements.forEach(element => {
      if (!element.hasAttribute(DISABLE_IGNORE_ATTR)) {
        element.disabled = true;
        element.setAttribute(DISABLED_ATTR, true);
      }
    });
  }

  enable() {
    const formElements = this.element.querySelectorAll(
      `input[${DISABLED_ATTR}], select[${DISABLED_ATTR}], textarea[${DISABLED_ATTR}], button[${DISABLED_ATTR}]`
    );

    formElements.forEach(element => {
      element.disabled = false;
      element.removeAttribute(DISABLED_ATTR);
    });
  }

  reenable() {
    if (this.submitEvent) {
      setTimeout(() => {
        Rails.enableElement(this.submitEvent);
        this.submitEvent = null;
      }, 50);
    }
  }

  requestSubmit() {
    // Passing the button allows turbo to automatically disable it.
    const submit = this.element.querySelector("[type=submit]");
    this.element.requestSubmit(submit);
  }

  get events() {
    return this.constructor.events;
  }
}
