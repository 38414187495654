import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  dispatch(
    eventName,
    {
      target = this.element,
      detail = {},
      bubbles = true,
      cancelable = true,
      namespace = this.identifier
    } = {}
  ) {
    const type = namespace ? `${namespace}:${eventName}` : eventName;
    const event = new CustomEvent(type, { detail, bubbles, cancelable });
    target.dispatchEvent(event);
    return event;
  }

  get classList() {
    return this.element.classList;
  }
}
