import { Controller } from "@hotwired/stimulus";
import { replaceParamValue } from "utils/ajax";
import { formatISODate } from "utils/format";

export default class extends Controller {
  static targets = ["dayFrom", "monthFrom", "yearFrom", "dayTo", "monthTo", "yearTo", "update"];
  static values = {
    followLink: Boolean
  };

  connect() {
    this.dayFromTarget.addEventListener("change", this.onChangeDateFrom.bind(this));
    this.monthFromTarget.addEventListener("change", this.onChangeDateFrom.bind(this));
    this.yearFromTarget.addEventListener("change", this.onChangeDateFrom.bind(this));

    this.dayToTarget.addEventListener("change", this.onChangeDateTo.bind(this));
    this.monthToTarget.addEventListener("change", this.onChangeDateTo.bind(this));
    this.yearToTarget.addEventListener("change", this.onChangeDateTo.bind(this));
  }

  disconnect() {
    this.dayFromTarget.removeEventListener("change", this.onChangeDateFrom.bind(this));
    this.monthFromTarget.removeEventListener("change", this.onChangeDateFrom.bind(this));
    this.yearFromTarget.removeEventListener("change", this.onChangeDateFrom.bind(this));

    this.dayToTarget.removeEventListener("change", this.onChangeDateTo.bind(this));
    this.monthToTarget.removeEventListener("change", this.onChangeDateTo.bind(this));
    this.yearToTarget.removeEventListener("change", this.onChangeDateTo.bind(this));
  }

  followLink() {
    const dateFromString = formatISODate(this.dateFrom);
    const dateToString = formatISODate(this.dateTo);
    let url = window.location.href;
    url = replaceParamValue(url, "date_from", dateFromString);
    url = replaceParamValue(url, "date_to", dateToString);
    Turbolinks.visit(url);
  }

  today(event) {
    event.preventDefault();
    let today = new Date();
    this.yearToTarget.value = today.getFullYear();
    this.monthToTarget.value = today.getMonth() + 1;
    this.dayToTarget.value = today.getDate();

    this.yearFromTarget.value = today.getFullYear();
    this.monthFromTarget.value = today.getMonth() + 1;
    this.dayFromTarget.value = today.getDate();

    this.followLink();
  }

  update(event) {
    event.preventDefault();
    this.followLink();
  }

  onChangeDateFrom() {
    this.handleEndOfMonthRollover(this.yearFromTarget, this.monthFromTarget, this.dayFromTarget);
    this.handleToDateBeforeFromDate(true);
    this.followLinkValue ? this.followLink() : this.highlightUpdateButton();
  }

  onChangeDateTo() {
    this.handleEndOfMonthRollover(this.yearToTarget, this.monthToTarget, this.dayToTarget);
    this.handleToDateBeforeFromDate(false);
    this.followLinkValue ? this.followLink() : this.highlightUpdateButton();
  }

  highlightUpdateButton() {
    this.updateTarget.classList.remove('btn-white');
    this.updateTarget.classList.add('btn-primary');
  }

  handleEndOfMonthRollover(yearTarget, monthTarget, dayTarget) {
    // if value is 31st Nov, change to 30th Nov (last day of month)
    const date = new Date(yearTarget.value, monthTarget.value - 1, dayTarget.value);

    if (date.getMonth() !== monthTarget.value - 1) {
      dayTarget.value = new Date(yearTarget.value, monthTarget.value, 0).getDate();
    }
  }

  handleToDateBeforeFromDate(updateTheToField) {
    // if the from date is past the to date, reset the to date to match the from date and vice versa
    if (this.dateFrom > this.dateTo) {
      if (updateTheToField) {
        this.yearToTarget.value = this.dateFrom.getFullYear();
        this.monthToTarget.value = this.dateFrom.getMonth() + 1;
        this.dayToTarget.value = this.dateFrom.getDate();
      }
      else {
        this.yearFromTarget.value = this.dateTo.getFullYear();
        this.monthFromTarget.value = this.dateTo.getMonth() + 1;
        this.dayFromTarget.value = this.dateTo.getDate();
      }
    }
  }

  get dateFrom() {
    return new Date(this.yearFromTarget.value, this.monthFromTarget.value - 1, this.dayFromTarget.value);
  }

  get dateTo() {
    return new Date(this.yearToTarget.value, this.monthToTarget.value - 1, this.dayToTarget.value);
  }
}
