import { toInteger } from "utils/parse";
import { format } from "date-fns";

function padDate(n) {
  return n < 10 ? `0${n}` : n;
}

function formatCurrency(amount) {
  let sign = "";

  amount = parseFloat(amount);

  if (isNaN(amount)) {
    amount = 0;
  }

  if (amount < 0) {
    sign = "-";
    amount = amount * -1;
  }

  amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return `${sign}$${amount}`;
}

function formatDate(date, formatString = "d MMMM yyyy") {
  return format(date, formatString);
}

function formatTime(date) {
  return format(date, "p");
}

function formatISODate(dateObj) {
  if (dateObj instanceof Date) {
    return [
      dateObj.getFullYear(),
      padDate(dateObj.getMonth() + 1),
      padDate(dateObj.getDate())
    ].join("-");
  } else {
    return "";
  }
}

export { formatCurrency, formatDate, formatTime, formatISODate };
