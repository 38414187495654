import { useClickOutside } from "stimulus-use";
import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static classes = ["container", "toggle"];
  static targets = ["togglee", "toggler"];
  static values = {
    hideAfter: Number,
    hideToggler: Boolean,
    hideOnOutsideClick: Boolean,
    bodyClass: String,
    showOnLoad: Boolean,
    inverse: Boolean,
    checkToggler: Boolean,
  };

  connect() {
    if (this.hasTogglerTarget && this.showOnLoad) {
      this.show();
    }

    if (this.hideOnOutsideClickValue) {
      this.watchOutsideClicks();
    }
  }

  disconnect() {
    this.hide();

    if (this.hideTogglerValue && this.hasTogglerTarget) {
      this.togglerTarget.style.display = null;
    }
  }

  watchOutsideClicks() {
    const [observe, unobserve] = useClickOutside(this, {
      element: this.toggleeTarget,
    });

    this.observe = observe;
    this.unobserve = unobserve;
    this.unobserve();
  }

  show() {
    this.toggleeTarget.classList.toggle(this.className, this.showBoolean);
    this.toggleBodyClass(this.showBoolean);
    this.toggleContainerClass(this.showBoolean);
    this.togglerAriaExpanded(true);
    this.togglerInputChecked(true);

    if (this.hasHideAfterValue) {
      setTimeout(() => this.hide(), this.hideAfterValue);
    }

    setTimeout(() => {
      this.observe && this.observe();
    });
  }

  hide() {
    this.toggleeTarget.classList.toggle(this.className, this.hideBoolean);
    this.toggleBodyClass(this.hideBoolean);
    this.toggleContainerClass(this.hideBoolean);
    this.togglerAriaExpanded(false);
    this.togglerInputChecked(false);

    this.unobserve && this.unobserve();
  }

  clickOutside(event) {
    if (this.hasTogglerTarget && this.togglerTarget.contains(event.target)) {
      return true;
    }

    this.hide();
  }

  toggle() {
    this.isVisible ? this.hide() : this.show();

    if (this.hideTogglerValue && this.hasTogglerTarget) {
      this.togglerTarget.style.display = "none";
    }
  }

  onToggle(event) {
    event.preventDefault();
    this.toggle();
  }

  togglerAriaExpanded(expanded) {
    if (this.hasTogglerTarget) {
      this.togglerTarget.setAttribute("aria-expanded", expanded.toString());
    }
  }

  togglerInputChecked(checked) {
    if (this.checkTogglerValue && this.hasTogglerTarget) {
      this.togglerTarget.checked = checked;
    }
  }

  toggleBodyClass(force) {
    if (this.hasBodyClassValue) {
      document
        .querySelector("body")
        .classList.toggle(this.bodyClassValue, force);
    }
  }

  toggleContainerClass(force) {
    if (this.hasContainerClass) {
      this.classList.toggle(this.containerClass, force);
    }
  }

  get showOnLoad() {
    return this.togglerTarget.checked || this.showOnLoadValue;
  }

  // Default: show removes the class
  // Inverse: show adds the class
  get showBoolean() {
    return this.inverseValue;
  }

  get hideBoolean() {
    return !this.showBoolean;
  }

  get isVisible() {
    const hasClass = this.toggleeTarget.classList.contains(this.className);
    return this.showBoolean === hasClass;
  }

  get className() {
    if (this.hasToggleClass) {
      return this.toggleClass;
    } else {
      return "d-none";
    }
  }
}
