const strToEl = (() => {
  const tmpEl = document.createElement("div");
  return str => {
    const cleanedInput = str.trim();
    tmpEl.innerHTML = cleanedInput;
    const firstChild = tmpEl.children[0];

    while (tmpEl.firstChild) {
      tmpEl.removeChild(tmpEl.firstChild);
    }

    return firstChild;
  };
})();

function removeNode(node) {
  node.parentNode.removeChild(node);
}

export { removeNode, strToEl };
