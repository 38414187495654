import * as Sentry from "@sentry/browser";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    allowUrls: [
      /https?:\/\/((qa|www)\.)?kcaloyalty\.com\.au/
    ],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      "TypeError: cancelled",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
  });
}
