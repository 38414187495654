import ApplicationController from "controllers/application_controller";

const Selectors = {
  FORM: "form"
};

const fileApi = !!window.File;
const FAKE_PATH = "fakepath";
const FAKE_PATH_SEPARATOR = "\\";

const getSelectedFiles = input => {
  if (input.hasAttribute("multiple") && fileApi) {
    return [].slice
      .call(input.files)
      .map(file => file.name)
      .join(", ");
  }

  if (input.value.indexOf(FAKE_PATH) !== -1) {
    const splittedValue = input.value.split(FAKE_PATH_SEPARATOR);
    return splittedValue[splittedValue.length - 1];
  }

  return input.value;
};

export default class extends ApplicationController {
  static targets = ["label", "input"];
  static values = {
    defaultText: String,
    labelText: String
  };

  // initialize() {
  //   this.resetBind = this.reset.bind(this);
  // }

  connect() {
    this.form = this.element.closest(Selectors.FORM);
    //this.form.addEventListener("reset", this.resetBind);
    this.change();
  }

  //disconnect() {
  //  this.form.removeEventListener("reset", this.resetBind);
  //}

  change() {
    if (this.hasLabelTarget) {
      const value = getSelectedFiles(this.inputTarget);

      if (value.length) {
        this.labelTarget.textContent = value;
      } else {
        this.labelTarget.textContent = this.defaultText;
      }
    }
  }

  get defaultText() {
    return this.hasDefaultTextValue ? this.defaultTextValue : "Choose file...";
  }
}
