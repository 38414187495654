export class Response {
  constructor(response, request) {
    this.response = response;
    this.request = request;
  }

  get statusCode() {
    return this.response.status;
  }

  get ok() {
    return this.response.ok;
  }

  get unauthenticated() {
    return this.statusCode == 401;
  }

  get authenticationURL() {
    return this.response.headers.get("WWW-Authenticate");
  }

  get contentType() {
    const contentType = this.response.headers.get("Content-Type") || "";
    return contentType.replace(/;.*$/, "");
  }

  get headers() {
    return this.response.headers;
  }

  get html() {
    if (this.contentType.match(/^(application|text)\/(html|xhtml\+xml)$/)) {
      return this.text;
    } else {
      return Promise.reject(
        `Expected an HTML response but got "${this.contentType}" instead`
      );
    }
  }

  get json() {
    if (this.contentType.match(/^application\/json/)) {
      return this.response.json();
    } else {
      return Promise.reject(
        `Expected a JSON response but got "${this.contentType}" instead`
      );
    }
  }

  async processJs() {
    const script = document.createElement("script");
    // script.setAttribute("nonce", cspNonce());
    script.text = await this.text;
    document.head.appendChild(script).parentNode.removeChild(script);
  }

  get isJs() {
    return this.contentType.match(/\b(?:java|ecma)script\b/);
  }

  get text() {
    return this.response.text();
  }
}
