import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "pass",
    "conf",
    "toggle",
    "iconReveal",
    "iconConceal"
  ];

  static values = {
    concealed: Boolean
  };

  connect() {
    if (!this.hasConcealedValue) {
      this.concealedValue = true;
    }

    this.render();
  }

  render() {
    if (this.concealedValue) {
      this.conceal();
    }
    else {
      this.reveal();
    }
  }

  toggle() {
    this.concealedValue = !this.concealedValue;
    this.render();
  }

  reveal() {
    this.passTarget.setAttribute('type','text');
    if (this.hasConfTarget) {
      this.confTarget.setAttribute('type','text');
    }
    this.iconRevealTarget.classList.toggle('d-none', false);
    this.iconConcealTarget.classList.toggle('d-none', true);
    this.concealedValue = false;
  }

  conceal() {
    this.passTarget.setAttribute('type','password');
    if (this.hasConfTarget) {
      this.confTarget.setAttribute('type','password');
    }
    this.iconRevealTarget.classList.toggle('d-none', true);
    this.iconConcealTarget.classList.toggle('d-none', false);
    this.concealedValue = true;
  }
}
