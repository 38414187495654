import Rails from "@rails/ujs";

function closest(element, selector) {
  let target = element;

  while (!(!(target instanceof Element) || matches(target, selector))) {
    target = target.parentNode;
  }

  return target;
}

function getParentController(controller, name) {
  return controller.application.getControllerForElementAndIdentifier(
    controller.element.closest(`[data-controller~="${name}"]`),
    name
  );
}

function getChildController(controller, name) {
  const element = controller.element.querySelector(
    `[data-controller~="${name}"]`
  );

  return controller.application.getControllerForElementAndIdentifier(
    element,
    name
  );
}

function getChildControllers(controller, name) {
  const childElements = Array.from(
    controller.element.querySelectorAll(`[data-controller~="${name}"]`)
  );

  const controllers = childElements.map(element => {
    return controller.application.getControllerForElementAndIdentifier(
      element,
      name
    );
  });

  return controllers.filter(controller => controller !== null);
}

function getSiblingController(controller, name) {
  return controller.application.getControllerForElementAndIdentifier(
    controller.element,
    name
  );
}

function getFieldName(fieldKey, resourceName) {
  let fieldName = resourceName;
  const parts = fieldKey.split(".");
  const field = parts.pop();

  parts.forEach(part => {
    fieldName += `[${part}_attributes]`;
  });

  return fieldName + `[${field}]`;
}

function matches(element, selector) {
  return Rails.matches(element, selector);
}

export {
  closest,
  getChildController,
  getChildControllers,
  getParentController,
  getSiblingController,
  getFieldName,
  matches
};
