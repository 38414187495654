// https://github.com/ddnexus/pagy/blob/master/lib/javascripts/README.md#propshaft

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!Pagy) return;

    Pagy.init(this.element);
  }
}
