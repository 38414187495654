import Rails from "@rails/ujs";
import { Request } from "utils/request";

function ajax(options) {
  if (options.data) {
    options = {
      ...options,
      data: toQueryString(options.data)
    };
  }

  return Rails.ajax(options);
}

function appendQuery(url, params) {
  if (url && params) {
    const separator = url.indexOf("?") > -1 ? "&" : "?";
    return `${url}${separator}${toQueryString(params)}`;
  }

  return url;
}

function buildQuery(key, value, add) {
  if (Array.isArray(value)) {
    value.forEach((v, i) => {
      const index = typeof v === "object" && v != null ? i : "";
      buildQuery(`${key}[${index}]`, v, add);
    });
  } else if (typeof value === "object" && value !== null) {
    Object.keys(value).forEach(name =>
      buildQuery(`${key}[${name}]`, value[name], add)
    );
  } else {
    add(key, value);
  }
}

function toQueryString(obj) {
  if (typeof obj === "string") {
    return obj;
  } else {
    const pairs = [];
    const add = (key, value) => {
      pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    };

    Object.keys(obj).forEach(key => buildQuery(key, obj[key], add));

    return pairs.join("&");
  }
}

function replaceParams(path, params) {
  let newPath = path;

  Object.keys(params).forEach(key => {
    const regexp = new RegExp(`([/]|^):${key}([\./?]|$)`, "g");
    newPath = newPath.replace(regexp, `$1${params[key]}$2`);
  });

  return newPath;
}

function replaceAndAppendParams(path, params) {
  let newPath = path;

  Object.keys(params).forEach(key => {
    const regexp = new RegExp(`([/]|^):${key}([\./?]|$)`, "g");

    if (newPath.search(regexp) >= 0) {
      newPath = newPath.replace(regexp, `$1${params[key]}$2`);
    } else {
      newPath = replaceParamValue(newPath, key, params[key]);
    }
  });

  return newPath;
}

function replaceParamValue(path, key, value) {
  if (value === null) {
    value = "";
  }

  let pattern = new RegExp(`\\b(${key}=).*?(&|#|$)`);

  if (path.search(pattern) >= 0) {
    return path.replace(pattern, `$1${value}$2`);
  }

  path = path.replace(/[?#]$/, "");
  const separator = path.indexOf("?") > 0 ? "&" : "?";

  return `${path}${separator}${key}=${value}`;
}

function removeParam(path, key) {
  let pattern = new RegExp(`\\b(${key}=).*?(&|#|$)`);

  if (path.search(pattern) >= 0) {
    path = path.replace(pattern, "$2");
  }

  path = path.replace(/^[&]/, "");
  path = path.replace(/[?#]$/, "");

  return path;
}

function serializeElement(element, additionalParam) {
  return Rails.serializeElement(element, additionalParam);
}

async function request(method, url, options) {
  const request = new Request(method, url, options);
  const response = await request.perform();
  if (!response.ok) throw new Error(response.statusCode);
  return request.responseKind == "json" ? response.json : response.text;
}

request.perform = async function(method, url, options) {
  const request = new Request(method, url, options);
  return request.perform();
};

["get", "post", "put", "delete"].forEach(method => {
  request[method] = (...args) => request(method, ...args);
});

request.getJSON = (url, options = {}) =>
  request.get(url, { responseKind: "json", ...options });

export {
  ajax,
  appendQuery,
  toQueryString,
  removeParam,
  replaceAndAppendParams,
  replaceParams,
  replaceParamValue,
  request,
  serializeElement
};
