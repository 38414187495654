import MoveTo from "moveto";

function scrollTo(element, options) {
  let container = window;

  if (document.body.classList.contains("modal-open")) {
    container = document.querySelector(".modal");
  }

  // Take into account sticky nav on the store
  if (window.innerWidth < 992) {
    const halo = document.querySelector(".nav-halo");

    if (halo) {
      options.tolerance = options.tolerance || 0;
      options.tolerance += halo.offsetHeight;
    }
  }

  const moveTo = new MoveTo();
  moveTo.move(element, { container, ...options });
}

export { scrollTo };
