import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

// Change the config to fix the flicker
config.mutateApproach = "sync";

import {
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faBars as farBars,
  faBan as farBan,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faClock as farClock,
  faCopyright as farCopyright,
  faExclamationTriangle as farExclamationTriangle,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faExchange as farExchange,
  faFile as farFile,
  faFileCheck as farFileCheck,
  faFilter as farFilter,
  faKey as farKey,
  faLock as farLock,
  faMinusCircle as farMinusCircle,
  faPaperPlane as farPaperPlane,
  faPencil as farPencil,
  faPlusCircle as farPlusCircle,
  faQuestionCircle as farQuestionCircle,
  faRegistered as farRegistered,
  faSave as farSave,
  faSearch as farSearch,
  faShieldCheck as farShieldCheck,
  faSortAmountDown as farSortAmountDown,
  faSync as farSync,
  faThumbsUp as farThumbsUp,
  faThumbsDown as farThumbsDown,
  faTimes as farTimes,
  faTrash as farTrash,
  faUnlock as farUnlock,
  faUser as farUser,
  faUsdCircle as farUsdCircle,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  farArrowLeft,
  farArrowRight,
  farBars,
  farBan,
  farCheck,
  farCheckCircle,
  farClock,
  farCopyright,
  farExclamationTriangle,
  farEye,
  farEyeSlash,
  farExchange,
  farFile,
  farFileCheck,
  farFilter,
  farKey,
  farLock,
  farMinusCircle,
  farPaperPlane,
  farPencil,
  farPlusCircle,
  farQuestionCircle,
  farRegistered,
  farSave,
  farSearch,
  farShieldCheck,
  farSortAmountDown,
  farSync,
  farThumbsUp,
  farThumbsDown,
  farTimes,
  farTrash,
  farUnlock,
  farUser,
  farUsdCircle,
);

dom.watch();
